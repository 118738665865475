<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="用户模版" name="first">
       <tableList v-if="activeName == 'first'"></tableList>
      </el-tab-pane>
<!--      <el-tab-pane label="内部模版" name="second">-->
<!--        <tableList2 v-if="activeName == 'second'"></tableList2>-->
<!--      </el-tab-pane>-->
    </el-tabs>
  </div>
</template>

<script>
import tableList from './components/tableList'
// import tableList2 from './components/tableList2'
export default {
  name: "customList",
  components:{
    tableList,
    // tableList2,
  },
  data() {
    return {
      activeName: 'first'
    };
  },
  computed: {
  },
  watch:{

  },
  created(){

  },
  methods:{


  }
};
</script>

<style scoped lang='scss'>

</style>
